import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

export default function  ObjecttypeSelectlist ({options, selected, handleChange, label, style}) {

    // const options = [
    //     {value: '', text: '-- Bitte wählen Sie einen Objekttypen --'},
    //     {value: 'territorialdivision', text: 'Gebietsgliederung'},
    //     {value: 'site', text: 'Liegenschaft'},
    //     {value: 'economicunit', text: 'Wirtschaftseinheit'},
    //     {value: 'structure', text: 'Bauwerk'},
    //     {value: 'building', text: 'Gebäude'},
    //     {value: 'storey', text: 'Geschoß'},
    //     {value: 'buildingentrance', text: 'Hauseingang'},
    //     {value: 'rentalunit', text: 'Mieteinheit'},
    //     {value: 'commercialunit', text: 'Gewerbeeinheit'},
    //     {value: 'apartment', text: 'Wohnung'},
    //     {value: 'room', text: 'Raum'},
    //     {value: 'playground', text: 'Spielplatz'},
    //     {value: 'parkingspace', text: 'Stellplatz'},
    // ];

    return (
        <>
            {label ? (
            <label >{label}</label>
                ): null}
            <Select
                id="objecttype-select"
                value={selected} onChange={handleChange}
                displayEmpty
                variant="outlined"
                sx={style}>
                {options?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}