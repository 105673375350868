import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import {toast, ToastContainer} from "react-toastify";
import http from "./http-common";
import ObjecttypeSelectlist from "./ObjecttypeSelectlist";

const styles = {
    itemText: {
        margin: "10px",
        paddingLeft: "30px",
    },
    ListComp: {
        padding: "30px",
        margin: "10px",
    },
    InputLabel: {
        width: "150px"
    },
    selectbox: {
        width: "300px"
    },
    TabBorderStyle: {
        padding: "3px",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    }
}

const DropdownWithAutocomplete = ({ TreeSelected, fetchAutocompleteData, realm, token }) => {
    const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAutocompleteOptions, setFilteredAutocompleteOptions] = useState([]);

    const dropdownOptions = [
        {value: '', text: '-- Bitte wählen Sie einen Objekttypen --'},
        {value: 'site', text: 'Liegenschaft'},
        {value: 'structure', text: 'Bauwerk'},
        {value: 'building', text: 'Gebäude'},
        {value: 'storey', text: 'Geschoß'},
        {value: 'room', text: 'Raum'},
        {value: 'playground', text: 'Spielplatz'},
    ];

    // Fetch autocomplete options when dropdown value changes
    // useEffect(() => {
    //     if (selectedDropdownValue) {
    //         fetchAutocompleteData(selectedDropdownValue)
    //             .then((data) => setAutocompleteOptions(data))
    //             .catch((error) => console.error("Error fetching autocomplete data:", error));
    //     } else {
    //         setAutocompleteOptions([]);
    //     }
    // }, [selectedDropdownValue, fetchAutocompleteData]);

    // Filter autocomplete options based on search term
    useEffect(() => {
        if (searchTerm) {
            const filtered = autocompleteOptions.filter((option) =>
                option.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredAutocompleteOptions(filtered);
        } else {
            setFilteredAutocompleteOptions(autocompleteOptions);
        }
    }, [searchTerm, autocompleteOptions]);

    const downloadTemplate = async () => {
        let formData = new FormData();
        formData.append("realm", realm);
        formData.append("guid", TreeSelected.global_id);
        formData.append("objecttypeguid", selectedDropdownValue);

        const fetchData = await http.post("/componentType/exportComponenttypeTemplate", formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                responseType: 'blob'
            }
        );

        const url = window.URL.createObjectURL(new Blob([fetchData.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', TreeSelected.name + '.xlsx'); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast('Die Template-Datei wurde in Ihrem Download-Ordner gespeichert.')
    }


    return (
        <>
    <div style={{textAlign: "left", marginTop:"10px"}}>

        <div style={{margin:"10px"}}>
            {/* Dropdown */}
            <ObjecttypeSelectlist
                options={dropdownOptions}
                selected={selectedDropdownValue}
                handleChange={(e) => setSelectedDropdownValue(e.target.value)}
            />


            {/* Autocomplete */}
            {/*            {selectedDropdownValue && (
                <div>
                    <label>
                        Search:
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Type to search..."
                        />
                    </label>
                    <ul>
                        {filteredAutocompleteOptions?.map((option, index) => (
                            <li key={index}>{option}</li>
                        ))}
                    </ul>
                </div>
            )}*/}
        </div>
        <div style={{margin:"10px"}}>
        <Button type="button" color="primary" variant="outlined" size="large" onClick={()=>downloadTemplate()}>Export Bauteiltyp-Template</Button>
        </div>
    </div>

    <ToastContainer />
        </>
    );
};

export default DropdownWithAutocomplete;

// Usage Example
// import DropdownWithAutocomplete from './DropdownWithAutocomplete';
//
// const dropdownOptions = ["Option1", "Option2", "Option3"];
//
// const fetchAutocompleteData = async (selectedValue) => {
//   // Simulate an API request
//   const response = await axios.get(`/api/data?value=${selectedValue}`);
//   return response.data;
// };
//
// <DropdownWithAutocomplete
//   dropdownOptions={dropdownOptions}
//   fetchAutocompleteData={fetchAutocompleteData}
// />;
